import React, { useState, useContext } from 'react'
import { useMount, useUnmount } from 'react-use'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import DynamicForm, { useForm } from 'react-dynamic-form'
import OutsideClickHandler from 'react-outside-click-handler';

import { Field, PageSection, MarkLink } from '../'

import { media, isClient } from '../../styles/utils'
import { container, padding, colours, type } from '../../styles/global'
import { validators } from '../../utils/form'
import { industryOptions } from './data'
import { subscribeContext } from './SubscribeProvider'
import { defaultEase } from '../../utils/anim'

const Subscribe = (props) => {
    const { zIndex } = props;
    const [form, { formSubmit, setError }] = useForm();
    const [formComplete, setFormComplete] = useState(false)
    const [requiredFieldsMissing, setRequiredFieldsMissing] = useState(false)
    const { active, setActive, defaultList, disableClose, useSocialField, setUseSocialField } = useContext(subscribeContext)

    useUnmount(() => {
        setUseSocialField(false)
    })

    const handleSubmit = (fields, resetForm) => {
        setRequiredFieldsMissing(false)

        let data = {
			email: fields['email'].value,
            firstName: fields['firstName'].value,
            lastName: fields['lastName'].value,
            industry: fields['industry'].value,   
            subscriberCode: fields['subscriberCode'].value,
            subscribeGroup1: false,
            subscribeGroup2: false,
        }

        if (fields['subscribeToLists'].value[0]?.slug==1) data.subscribeGroup1 = true
        if (fields['subscribeToLists'].value[0]?.slug==2) data.subscribeGroup2 = true
        if (fields['subscribeToLists'].value[1]?.slug==1) data.subscribeGroup1 = true
        if (fields['subscribeToLists'].value[1]?.slug==2) data.subscribeGroup2 = true

        let mailchimpData = {
            FNAME: data.firstName,
            LNAME: data.lastName,
            'group[10873]': data.industry,
            'MMERGE4': data.subscriberCode,
            'group[10877][268435456]': data.subscribeGroup1,
            'group[10877][536870912]': data.subscribeGroup2,
        }
        
        if (useSocialField) {
            mailchimpData['MMERGE3'] = 'social'
        }

        addToMailchimp(data.email, mailchimpData).then(mc_resp => {
            if (mc_resp.result == 'success') {
                setFormComplete(true)
                setError(false)
            } 

            if (mc_resp.result == 'error') {
                setError(mc_resp.msg)
            }
        })
        
	    resetForm()
    }

    const getDefaultOptions = (defaultList) => {
        if (defaultList == 'live') {
            return [
                {
                    label: 'CR/Live',
                    slug: '1',
                },
            ]
        } else {
            return [
                {
                    label: 'CR/Insights',
                    slug: '2',
                },
            ]
        }
    }

    const renderForm = () => {
        return (
           <SectionContent>
                {!formComplete && (
                    <>
                        <DynamicForm
                            form={form}
                            renderInput={<Field/>}
                            data={subscribeFields(getDefaultOptions(defaultList))}
                            onSubmit={handleSubmit}
                            renderSubmit={false}
                            moveToInvalidField={false}
                        />
        
                        <MarkLink
                            onClick={() => {
                                setRequiredFieldsMissing(true)
                                formSubmit()
                            }}
                        >
                            Submit
                        </MarkLink>
        
                        {form.error && (
                            <Error
                                dangerouslySetInnerHTML={{__html: form.error}}
                            />
                        )}

                        {requiredFieldsMissing && (
                            <Error
                                dangerouslySetInnerHTML={{__html: 'Please fill in mandatory fields*'}}
                            />
                        )}
                    </>
                )}

                {formComplete && (
                    <Subheading>
                        Thanks for <br/>
                        Subscribing.
                    </Subheading>
                )}
           </SectionContent>
        )
    }
    
    const renderFormSection = () => {
        const section = {
			title: formComplete ? 'Confirmed' : 'Subscribe',
			renderContent: renderForm
		}	

		return (
			<PageSection 
				{...section}
			/>
		)        
    }

	return (
        <OutsideClickHandler
            onOutsideClick={() => {
                active && !disableClose && setActive(false)
            }}
        >
            <Wrapper
                active={active}
                initial={'hide'}
                animate={active ? 'show' : 'hide'}
                zIndex={zIndex}
            >
                <Container
                    disableClose={disableClose}
                    {...containerAnim}
                >
                    {active && renderFormSection()}

                    {!disableClose && (
                        <Close
                            onClick={() => setActive(false)}
                        >
                            Close
                        </Close>
                    )}
                </Container>	

                <Background
                    {...backgroundAnim}
                />	
            </Wrapper>
        </OutsideClickHandler>
	)
}

// Shared

const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

// Layout

const Wrapper = styled(motion.div)`
	display: flex;
	flex-direction: column;
	flex: 1;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    pointer-events: none;

    ${props => {
        if (props.active) return css`
            pointer-events: all;
        `
    }}

    ${props => {
        if (props.zIndex) return css`
            z-index: ${props => props.zIndex}
        `
    }}
`

// Container

const containerAnim = {
	transition: {
		duration: 0.35,
		easing: defaultEase
	},
	variants: {
		hide: {
			opacity: 0,
		},
		show: {
			opacity: 1,
			transition: {
				delay: 0.45
			}
		},
	}
}

const Container = styled(motion.div)`
	${container}
	${padding}

	display: flex;
	flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    max-height: 847px;
    height: 770px;
    z-index: 2;
    opacity: 0;

    @media (max-height: 800px) {
        height: 70vh;
    }

    ${media.phone`
        height: 70vh;
    `}

    .page-section {
        > * {
            color: black;
        }

        .mark {
            background: black;
        }
    }

    ${props => {
        if (props.disableClose) return css`
            max-height: none;
            height: calc(100vh - 120px);

            ${media.phone`
                height: calc(86.5vh - 76px);
            `}
        `
    }}
`

// Background

const backgroundAnim = {
	transition: {
		duration: 0.45,
		easing: 'easeOut'
	},
	variants: {
		hide: {
			scaleY: 0,
			originY: 1,
			transition: {
				delay: 0.1
			}
		},
		show: {
			scaleY: 1,
			originY: 1,
		},
	}
}

const Background = styled(motion.div)`
	background: black;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
    background: ${colours.lemon};
    transform: scaleY(0);
`

// Close

const Close = styled(motion.div)`
	${type.heading5};
	cursor: pointer;
    color: black;

	position: absolute;
	top: 50px;
	right: 30px;
	z-index: 10;

    ${media.phone`
        right: 15px;
    `}
`

const MailchimpError = styled.div``
const SectionContent = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${Subheading}, ${MailchimpError} {
        ${type.heading3};
        color: black;
    }
    ${MailchimpError}{
        margin-top: 40px;
    }

    .mark-link {
        margin-top: 26px;
        
        > *, 
        > * > * {
            color: black;
        }
    }
`

export const Error = styled.div`
    ${type.heading5}	
    font-weight: 500;
    margin-top: 8px;
    max-width: 400px;

    &, a {
        color: ${colours.red};
        
        a {
            box-shadow:
                inset 0 -0.175em ${colours.lemon},
                inset 0 -0.22em ${colours.red};
        }
    }
`


// Subscribe fields

const subscribeFields = (defaultOptions) => {
    return [
        {
            type: 'text',
            name: 'firstName',
            placeholder: 'First Name*',
            validator: validators.required,
            errorMessage: 'Please enter a valid name',
        },
        {
            type: 'text',
            name: 'lastName',
            placeholder: 'Last Name*',
            validator: validators.required,
            errorMessage: 'Please enter a valid name',
        },
        {
            type: 'email',
            name: 'email',
            placeholder: 'Email Address*',
            validator: validators.required,
            errorMessage: 'Please enter a valid email',
        },
        {
            type: 'select',
            name: 'industry',
            label: 'Industry',
            options: industryOptions
        },
        {
            type: 'text',
            name: 'subscriberCode',
            placeholder: 'Subscriber Code',
        },
        {
            type: 'checkbox',
            name: 'subscribeToLists',
            errorMessage: 'A selection is required',
            defaultOptions: defaultOptions,
            options: [
                {
                    label: 'CR/Live',
                    slug: '1',
                },
                {
                    label: 'CR/Insights',
                    slug: '2',
                },
            ]
        },
    ]
}

export default Subscribe
