import { 
    BlockText,
    BlockTextImage
} from './templates'

export const resolveBlock = (layout) => {
    let block;

    // Map layout strings to blocks

    const blocks = {
        'text': BlockText,
        'text_image': BlockTextImage
    }
    
    return blocks[layout]
}


