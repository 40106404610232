import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'

import { type, colours } from '../../styles/global'
import { media, useBreakpoint } from '../../styles/utils'
import { subscribeContext } from '../Subscribe/SubscribeProvider'
import { useScrollAnim, transition } from '../../utils/anim'

const MarkLink = (props) => {
	const { children, backendLink, link, openSubscribe, disableAnimation } = props;
	const subscribe = useContext(subscribeContext)
	const isPhone = useBreakpoint('phone')

	const resolveLink = () => {
		let linkProps = {}

		if (backendLink) {
			if (backendLink.type == 'internal') {
				linkProps = {
					as: Link,
					to: backendLink.url
				}
			}

			if (backendLink.type == 'external') {
				linkProps = {
					as: 'a',
					href: backendLink.url,
					target: '_blank'
				}
			}
		}

		if (link) {
			linkProps = {
				as: Link,
				to: link
			}
		}

		if (openSubscribe) {
			linkProps = {
				onClick: () => {
					subscribe.setDefaultList('insights')
					subscribe.setActive(true)
				}
			}
		}

		return linkProps
	}

	const [ref, { scaleX, opacity }] = useScrollAnim({threshold: 1});

	let anim;

	if (isPhone) {
		anim = {
			opacity,
			transition: {
				...transition,
				delay: 0.35
			}
		}
	} else {
		anim = {
			scaleX,
			originX: 0,
			transition: {
				...transition,
				delay: 0.25,
				duration: 0.2
			}
		}
	}

	return (
		<Wrapper
			className={'mark-link'}
			ref={props.innerRef}
			{...props}
		>
			<LinkWrapper
				{...resolveLink()}
			>
				<Mark
					ref={ref}
					className={'mark'}
					animate={!disableAnimation && {
						...anim
					}}
				/>
				<Text
					animate={!disableAnimation && {
						opacity,
					}}
					transition={{
						...transition,
						delay: 0.45
					}}
				>
					{children}
				</Text>
			</LinkWrapper>
		</Wrapper>
	)
}

const Text = styled(motion.div)`
	display: flex;
	align-items: center;
	white-space: pre;
`

const Mark = styled(motion.div)`
	width: 54px;
	height: 11px;
	background: ${colours.lemon};
	transform: translate3d(0,0,0);
	
	${media.phone`
		will-change: opacity;
	`}
`

const LinkWrapper = styled.div`
	
`

const Wrapper = styled(motion.a)`
	overflow: hidden;
	will-change: translate;
	transform: translate3d(0,0,0);

	> * {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	${Text} {
		margin-left: 12px;
		${type.heading5}	
	}

	&:hover {
		${Mark} {
			width: 60px;
			transition: all 0.25s ease;
		}
	}
`


export default MarkLink
