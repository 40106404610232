import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion, transform } from 'framer-motion'
import ReactHtmlParser from 'react-html-parser';
import useDimensions from 'react-use-dimensions';

import { Mark } from '../'

import { type, colours } from '../../styles/global'
import { media } from '../../styles/utils'
import { transition } from '../../utils/anim'
import { resolveMark } from '../Mark/Mark';
import { useWindowSize } from 'react-use';

const AnimatedText = (props) => {
	const { text, className, animate: enableAnimation, force } = props;
	const { height } = useWindowSize()
	const [ref, { y, top }] = useDimensions();

	const animate = force || y < height * 0.8;
	const opacity = animate ? 1 : 0
	const x = animate ? 0 : -30
	const scaleX = animate ? 1 : 0

	const getOptions = () => {
		return {
			transform: (node, index) => {
				if (node.name == 'mark') {
					const mark = resolveMark(node?.children?.[0].data);

					if (mark) {
						return (
							<Mark
								animate={enableAnimation !== false && {
									originX: 0,
									scaleX,
									transition
								}}
								{...mark}
							/>
						)
					}
				}
			} 
		}
	}
	
	return (
		<Wrapper
			ref={ref}
			className={className}
			animate={enableAnimation !== false && {
				x,
				opacity,
				transition
			}}
			// style={animate !== false && {
			// 	// opacity,
			// 	x
			// }}
		>
			{ReactHtmlParser(text, getOptions())}
		</Wrapper>
	)
}

const Text = styled.div``


const Wrapper = styled(motion.div)`
	will-change: translate;
	transform: translate3d(0,0,0);

	.mark {
		display: inline-block;
	}
`


export default AnimatedText
