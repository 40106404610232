// Validators

const required = (value) => value && value !== "" || value?.length >= 1;

const requiredSelect = (selectedOption) => {
    if (selectedOption?.option) return true
}

const requiredBool = (value) => {return value};

const email = value => {
    const emailTest = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailTest.test(value)
}

const phone = value => {
    if (!value) return false
    const phoneTest = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;
    return phoneTest.test(value)
}

const number = value => {
    const numberTest = /^[0-9]+$/;
    return numberTest.test(value)
}

const password = value => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(value);
}

const date = dates => {
    const { startDate, endDate } = dates || {}
    if (startDate) return true
}


export const validators = {
    required,
    requiredSelect,
    requiredBool,
    email,
    phone,
    number,
    password,
    date
}