import React from 'react'
import styled, { css } from 'styled-components'
import { type, colours } from '../../styles/global'
import { media } from '../../styles/utils'

// Field Message

const message = css`
	${type.heading5}	
`

// Error

export const renderError = ({errorMessage, shouldValidate, isValid}, focused) => {    
    return; // disable message

    if (!shouldValidate || isValid) return;
    if (!isValid && focused) return;
    
    return (
        <Error
            className={'error'}
        >
            {errorMessage}
        </Error>
    )
}

export const hasError = ({errorMessage, shouldValidate, isValid}, focused) => {    
    if (!shouldValidate || isValid) return;
    if (!isValid && focused) return;

    return true
}

// Error

export const Error = styled.div`
    ${type.heading5}	
    color: ${colours.red};
    font-weight: 500;
    margin-top: 16px;
`

// Shared Styles

export const inputBase = css`
 	height: 32px;
    width: 100%;
    background: none;

    appearance: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    padding: 0;

    &, &::placeholder {
        font-family: 'FavoritStd';
        ${type.heading3};
        color: black;
    }

    ${media.phone`
        transform: translateY(-1px);

        &, &::placeholder {
            line-height: 1em;
        }

        &::placeholder {
            /* padding-top: 8px; */
        }
    `}
`