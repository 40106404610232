import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import Lottie from '../../../utils/react-lottie-player'
import { useInView } from 'react-intersection-observer'
import ReactPlayer from 'react-player'

import { AnimatedText, MarkLink } from '../../'

import { media } from '../../../styles/utils'
import { type, colours, bgImage } from '../../../styles/global'
import ProgressiveImage from 'react-progressive-image'
import { useScrollAnim, defaultEase } from '../../../utils/anim'
import { useMount } from 'react-use'

const BlockTextImage = (props) => {
	const { heading, text, type_size, image_or_animation } = props;
	
	const getIntroText = () => {
		if (type_size && type_size == 'large') {
			return text
		}

		if (type_size && type_size == 'medium') {
			return text
		}

		if (props.intro_text) {
			return props.intro_text
		}
	}
	
	const getBodyText = () => {
		if (type_size && type_size !== 'large') {
			return props.text_small
		}

		if (props.body_text) {
			return props.body_text
		}

		if (props.text_body) {
			return props.text_body
		}
	}

	const renderTitle = () => {
		if (!heading) return

		return (
			<Title
				className={'title'}
				as={AnimatedText}
				text={heading}
			/>
		)
	}

	const renderIntro = () => {
		const introText = getIntroText()

		if (introText) {
			return (
				<Intro
					className={'intro'}
					typeSize={type_size}
				>
					<Text
						as={AnimatedText}
						text={introText}
						animate={!props.disableTextAnimation}
					/>
				</Intro>
			)
		}
	}

	const renderBody = () => {
		const bodyText = getBodyText()

		if (bodyText) {
			return (
				<Body
					className={'body'}
					as={AnimatedText}
					text={bodyText}
				/>
			)
		}
	}

	const resolveAnimation = (animation) => {
		const anims = {
			'new-customer': require('../../../assets/anim/new-customer.json'),
			'reinvented-business': require('../../../assets/anim/reinvented-business.json'),
			'resilient-leadership': require('../../../assets/anim/resilient-leadership.json'),
			'future-trust': require('../../../assets/anim/future-trust.json'),
			'radical-innovation': require('../../../assets/anim/radical-innovation.json'),
			'hyper-disruption': require('../../../assets/anim/hyper-disruption.json'),
		}

		return anims[animation]
	}
	

	const renderAnimation = () => {
		if (image_or_animation && image_or_animation !== 'animation') return;
		const [lottie, setLottie] = useState(false)
		const [ref, { opacity }] = useScrollAnim();

		useEffect(() => {
			if (opacity == 1 && lottie?.isPaused) {
				lottie.setDirection(1)
				lottie.setSpeed(1)
				lottie.goToAndPlay(0)
			}	
			if (opacity == 0 && lottie.currentFrame > 1) {
				lottie.setDirection(-1)
				lottie.setSpeed(2)
				lottie.play()
			}			
		}, [opacity])

		return (
			<AnimationWrapper
				ref={ref}
				animate={{
					opacity
				}}
			>
				<Lottie 
					getLottieInstance={setLottie}
					animationData={resolveAnimation(props.animation)}
					autoplay={false}
					loop={false}
				/>
			</AnimationWrapper>
		
		)
	}

	const renderImage = () => {
		if (image_or_animation && image_or_animation !== 'image') return;

		return (
			<Img 
				src={props.image?.sizes?.medium2} 
			/>
		)
	}

	const renderVideo = () => {
		if (image_or_animation && image_or_animation !== 'video') return;

		return (
			<Video
				url={props.video}
				playsinline
				muted
				autoPlay
				playing
				loop
				width={'100%'}
				height={'auto'}
				config={{
					file: {
						attributes: { 
							preload: true
						}
					}
				}}
			/>
		)
	}

	const renderLink = () => {
		if (props.use_link_or_expander && props.use_link_or_expander !== 'link') return
		if (!props.link_text) return

		const { link_type } = props;

		return (
			<MarkLink
				openSubscribe={link_type == 'subscribe'}
				backendLink={link_type !== 'subscribe' && props.link}
			>
				{props.link_text}
			</MarkLink>
		)
	}

	const renderAccordion = () => {
		if (props.use_link_or_expander !== 'expander') return
		const [expanded, setExpanded] = useState(false)

		return (
			<Accordion>
				<HiddenText
					animate={expanded ? 'show' : 'hide'}
					{...hiddenTextAnim}
				>
					<Text
						as={AnimatedText}
						animate={false}
						text={props.expanded_text}
					/>
				</HiddenText>

				<MarkLink
					onClick={() => setExpanded(!expanded)}
				>
					{expanded ? 'Close' : 'Learn more'}

					<Indicator
						expanded={expanded}
					/>
				</MarkLink>
			</Accordion>
		)
	}

	const renderMedia = (breakpoint) => {
		if (!props.image && !props.video && !props.animation) return;
		const [ref, { scaleX, transition }] = useScrollAnim({reverse: true});

		return (
			<Media
				className={'media'}
				ref={ref}
				breakpoint={breakpoint}
			>
				{renderImage()}
				{renderVideo()}
				{renderAnimation()}

				{image_or_animation && image_or_animation !== 'animation' && (
					<MediaBlock
						animate={{
							scaleX,
							originX: '100%',
							transition
						}}  
					/>
				)}
			</Media>
		)		
	}

	const renderAdditionalContent = () => {
		if (!props.additionalContent) return;
		const [ref, { opacity, transition }] = useScrollAnim();

		return (
			<AdditionalContent
				ref={ref}
				animate={{
					opacity,
					transition: transition
				}}
			>
				{props.additionalContent}
			</AdditionalContent>
		)
	}
	
		
	return (
		<>
			<Wrapper>
				<Content
					className={'content'}
				>
					{renderTitle()}
					{renderMedia('phone')}
					{renderIntro()}
					{renderBody()}
					{renderLink()}
					{renderAccordion()}
				</Content>
				
			
				{renderMedia('desktop')}
			</Wrapper>

			{renderAdditionalContent()}
		</>
	)
}


// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Text = styled.div``
const Img = styled.img``

// Layout

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;

	.mark-link {
		margin-top: auto;
	}

	${media.phone`
		display: flex;
		flex-direction: column;
	`}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 118px;
	max-width: 587px;

	> *:not(:last-child) {
		margin-bottom: 26px;		
	}

	p:last-child {
		margin-bottom: 0;
	}

	${media.phone`
		margin-right: 0;
		order: 2;
		max-width: none;
	`}
`

// Title

const Title = styled.div`
	${type.heading2};
`

// Intro

const Intro = styled.div`
	${Text} {
		${type.heading3}
	}

	/* Medium text */ 

	${props => {
		if (props.typeSize == 'medium') return css`
			${Text} {
				${type.heading4};

				${media.phone`
					${type.heading3}
				`}
			}
		` 
	}}
`

// Body

const Body = styled.div`
	${type.body}
`

// Media

const Media = styled.div`
	max-width: 440px;
	position: relative;

	img {
		max-width: 440px;
	}

	${media.phone`
		max-width: 100%;
		margin-bottom: 25px;

		img {
			max-height: 200px;
			max-width: 100%;
		}
	`}

	/* Video */

	video {
		height: auto !important;
	}

	/* Breakpoint display */

	${props => {
		if (props.breakpoint == 'desktop') return css`
			${media.phone`
				display: none;
			`}
		`

		if (props.breakpoint == 'phone') return css`
			display: none;

			${media.phone`
				display: flex;
			`}
		`
	}}
`

const MediaBlock = styled(motion.div)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: black;
	z-index: 2;
`

// Media (Video)

const Video = styled(ReactPlayer)`
	width: 100% !important;
`

// Animation

const AnimationWrapper = styled(motion.div)`

`

// Accordion

const hiddenTextAnim = {
	transition: {
		ease: defaultEase
	},
    variants: {
		hide: {
			opacity: 0,
			height: 0,
			marginBottom: 0
		},
		show: { 
			opacity: 1,
			marginBottom: 26,
			height: 'auto'
		},
	}
}


const HiddenText = styled(motion.div)`
	${type.body}
`

const Accordion = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 0 !important;
`

const Indicator = styled.div`
	height: 12px;
	width: 12px;
	margin-left: 8px;
	${bgImage};
	transform: translateY(-1px);

	${props => {
		if (props.expanded) return css`
			background-image: url(${require('../../../assets/icons/accordion-hide.svg')});
		`

		if (!props.expanded) return css`
			background-image: url(${require('../../../assets/icons/accordion-show.svg')});
		`
	}}
	
`

// Additional Content 

const AdditionalContent = styled(motion.div)`

`

BlockTextImage.wrapper = css`

`

export default BlockTextImage
