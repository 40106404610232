import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { PageSection, MarkLink, AnimatedText } from '..'

import { media } from '../../styles/utils'
import { container, padding, colours, type, bgImage, bgIcon, hoverState } from '../../styles/global'
import { defaultEase } from '../../utils/anim'
import { subscribeContext } from '../Subscribe/SubscribeProvider'
import { parseACF } from '../../utils'
import { useStaticQuery } from 'gatsby'

const SubscribeBanner = (props) => {
    const { active, inline } = props
    const [formComplete, setFormComplete] = useState(false)
    const subscribe = useContext(subscribeContext)
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)

    const renderSectionContent = () => {
        return (
            <SectionContent>
                <Subheading
                    as={AnimatedText}
                    animate={false}
                    text={data.subscribe_text}
                />

                <MarkLink
                    disableAnimation={props.disableMarkAnimation}
                    onClick={() => {
                        subscribe.setDefaultList('live')
                        subscribe.setActive(true)
                        props.toggle?.(false)
                    }}
                >
                    {data.subscribe_button_text}
                </MarkLink>
            </SectionContent>
        )
    }
    
    const renderSection = () => {
        const section = {
			title: 'Subscribe',
			renderContent: renderSectionContent
		}	

		return (
            <PageSection 
                disableAnimation={true}
				{...section}
			/>
		)        
    }

	return (
        <Wrapper
            active={active}
            inline={inline}
            initial={inline ? 'show' : 'hide'}
            animate={(inline || active) ? 'show' : 'hide'}
            
        >
            <Container
                {...containerAnim}
            >
                {renderSection()}

                {props.toggle && (
                    <Close
                        onClick={() => props.toggle(false)}
                    >
                        Close
                    </Close>
                )}
            </Container>	

            <Background
				{...backgroundAnim}
			/>	
        </Wrapper>
	)
}

// Shared

const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

// Layout

const Wrapper = styled(motion.div)`
	display: flex;
	flex-direction: column;
    align-items: center;
	flex: 1;
    width: 100%;

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    pointer-events: none;

    ${props => {
        if (props.active) return css`
            pointer-events: all;
        `
    }}

    /* Inline */

    ${props => {
        if (props.inline) return css`
            position: relative;
            pointer-events: all;
            z-index: 1;
        `
    }}
`

// Container

const containerAnim = {
	transition: {
		duration: 0.35,
		easing: defaultEase
	},
	variants: {
		hide: {
			opacity: 0,
		},
		show: {
			opacity: 1,
			transition: {
				delay: 0.45
			}
		},
	}
}

const Container = styled(motion.div)`
	${container}
	${padding}

	display: flex;
	flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    z-index: 2;

    .page-section {
        > * {
            color: black;
        }
        
        .mark {
            background: black;
        }
    }

    ${media.phone`
        padding-top: 56px;
        padding-bottom: 56px;
    `}
`

// Background

const backgroundAnim = {
	transition: {
		duration: 0.45,
		easing: 'easeOut'
	},
	variants: {
		hide: {
			scaleY: 0,
			originY: 1,
			transition: {
				delay: 0.1
			}
		},
		show: {
			scaleY: 1,
			originY: 1,
		},
	}
}

const Background = styled(motion.div)`
	background: black;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
    background: ${colours.lemon};
`

// Close

const Close = styled(motion.div)`
	${type.heading5};
	cursor: pointer;
    color: black;

	position: absolute;
	top: 50px;
	right: 30px;
	z-index: 10;

    ${media.phone`
        right: 15px;
    `}
`

const SectionContent = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;

    ${Subheading} {
        ${type.heading3};
        max-width: 840px;

        p {
            margin-bottom: 0;
        }
        
        *, 
        > * ,
        > * > * {
            color: black;
        }
    }

    .mark-link {
        margin-top: 26px;
        
        > *, 
        > * > * {
            color: black;
        }
    }

    ${media.phone`
        &:last-child {
            margin-bottom: 0 !important;
        }
    `}
`

export const Error = styled.div`
    ${type.heading5}	
    color: ${colours.red};
    font-weight: 500;
    margin-top: 16px;
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "live" } }) {
            nodes {
                title
                slug
                acf_json
            }
        }
    }
`

export default SubscribeBanner
