import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player'
import moment from 'moment'
import { useUnmount, useMount } from 'react-use'
import FullHeight from 'react-div-100vh'

import { Swiper, SubscribeBanner } from '..'

import { media, useBreakpoint, isClient } from '../../styles/utils'
import { container, padding, hoverState, bgImage, colours, type } from '../../styles/global'
import { defaultEase } from '../../utils/anim'

const VideoModal = (props) => {
	const { close, showSubscribe } = props;
	const [subscribeActive, setSubscribeActive] = useState(false)
	const [active, setActive] = useState(false)

	useMount(() => {
		document.body.style.overflow = 'hidden';
		toggleSubscribe()
		
		setTimeout(() => {
			setActive(true)
		}, 250);
	})

	useUnmount(() => {
		setSubscribeActive(false)
		document.body.style.overflow = 'auto';
	})
	
	const toggleSubscribe = () => {
		if (!showSubscribe) return;
		
		if (localStorage.subscribeSeen == 'true') {
			if (parseInt(localStorage.subscribeSeenExpires) > moment().unix()) {
				return
			}
		}

		setSubscribeActive(true)
		localStorage.subscribeSeen = true
		localStorage.subscribeSeenExpires = moment().add(14, 'days').unix()
	}	

	const closeModal = (params) => {
		setActive(false)
		setSubscribeActive(false)
			
		setTimeout(() => {
			close()
		}, 450);
	}

	const getVideoURL = (iframe) => {
		if (iframe) {
			return iframe?.match(/src="(.+?)"/)?.[1]
		}
	}

	const renderVideo = () => {
		if (!props.video) return;

		return (
			<VideoWrapper
				{...videoAnim}
			>
				<Video
					url={getVideoURL(props.video)}
					height={'100%'}
					autoplay
					playing={active}
					controls
					showinfo={0}
				/>
			</VideoWrapper>
		)
	}

	const renderSubscribe = () => {
		if (!active || !showSubscribe) return;
		
		return (
			<SubscribeBanner
				toggle={setSubscribeActive}
				active={subscribeActive}
				disableMarkAnimation={true}
			/>
		)
	}
	
	return (
		<FullHeight>
			<Wrapper
				active={active}
				animate={active ? 'show' : 'hide'}
			>			
				<Close
					onClick={closeModal}
					{...uiAnim}
				>
					Close
				</Close>

				{renderVideo()}	
				{renderSubscribe()}

				<Background
					{...backgroundAnim}
				/>		
			
			</Wrapper>
		</FullHeight>
	)
}


// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``

// Layout

const Wrapper = styled(motion.div)`
	width: 100vw;
	height: 100%;

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 11;

	display: flex;
	flex-direction: column;
	align-items: center;

	pointer-events: none;

	${props => {
		if (props.active) return css`
			pointer-events: all;
		`
	}}
`

const Container = styled.div`
	${container}
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`

// Background

const backgroundAnim = {
	transition: {
		duration: 0.45,
		easing: 'easeOut'
	},
	variants: {
		hide: {
			scaleY: 0,
			originY: 1,
			transition: {
				delay: 0.1
			}
		},
		show: {
			scaleY: 1,
			originY: 1,
		},
	},
	initial: {
		scaleY: 0,
		originY: 1,
		transition: {
			delay: 0.1
		}
	},
}

const Background = styled(motion.div)`
	background: black;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
`

const Close = styled(motion.div)`
	${type.heading5};
	cursor: pointer;

	position: fixed;
	top: 30px;
	right: 30px;
	z-index: 10;

	${media.phone`
		top: 15px;
		right: 15px;
	`}
`

// Video Wrapper

const videoAnim = {
	transition: {
		duration: 0.35,
		easing: defaultEase
	},
	variants: {
		hide: {
			opacity: 0,
		},
		show: {
			opacity: 1,
			transition: {
				delay: 0.65
			}
		},
	},
	initial: {
		opacity: 0,
	},
}

const VideoWrapper = styled(motion.div)`
	z-index: 2;
	position: relative;
	width: 100%;
	height: 100%;
	padding: 60px 0;
	box-sizing: border-box;

	${media.phone`
		padding: 40px 0;
	`}
`

const Video = styled(ReactPlayer)`
	width: 100% !important;
`

// Anim Shared

const uiAnim = {
	transition: {
		duration: 0.45,
		easing: defaultEase
	},
	variants: {
		hide: {
			opacity: 0,
		},
		show: {
			opacity: 1,
			transition: {
				delay: 0.5
			}
		},
	},
	initial: {
		opacity: 0,
	},
}


export default VideoModal
