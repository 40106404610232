import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player'

import { media } from '../../styles/utils'
import { bgImage } from '../../styles/global'

const VideoCover = (props) => {
	const { size } = props;

	const getVideoURL = (iframe) => {
		if (iframe) {
			return iframe?.match(/src="(.+?)"/)?.[1]
		}
	}

	return (
		<Wrapper
			className={'video-cover'}
		>
			<Video
				url={getVideoURL(props.data?.video)}
				light={props.data?.cover_image_override?.sizes?.medium2}
				width={'100%'}
				height={'100%'}
			/>
		</Wrapper>
	)
}

// Shared

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const Video = styled(ReactPlayer)`
	width: 100% !important;	
`

// Layout

const Wrapper = styled(motion.div)`
	width: 100%;
	height: 100%;
	cursor: pointer;
	overflow: hidden;

	.react-player__preview,
	.react-player__play-icon {
		transition: transform 1s ease;
		will-change: transform;
		transform: scale(1.0001);
	}

	&:hover {
		.react-player__preview {
			transform: scale(1.05) !important;
		}

		.react-player__play-icon {
			transform: scale(.95) !important;
		}
	}

`


export default VideoCover
