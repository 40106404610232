import React, { useState } from 'react'
import ProgressiveImage from 'react-progressive-image'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import FullHeight from 'react-div-100vh'
import ReactPlayer from 'react-player'
import { Mark, AnimatedText } from '../'

import { type, colours, bgImage, padding, } from '../../styles/global'
import { media } from '../../styles/utils'
import { useScrollAnim, transition } from '../../utils/anim'

import logoCondensed from '../../assets/images/logo-condensed.svg'
import { useMount } from 'react-use'

const PageHero = (props) => {
	const { data, slim } = props;
	const [animate, setAnimate] = useState(false)

	useMount(() => {
		setTimeout(() => {
			setAnimate(true)
		}, 700);
	})

	const renderImage = () => {
		if (data?.hero_image_or_video == 'video' || !data?.hero_image) return;

		return (
			<Image
				key={data?.hero_image?.sizes?.full_width}
				src={data?.hero_image?.sizes?.full_width}
			>
				{(src, loading) => {
					return (
						<BGImage 
							image={data?.hero_image?.sizes?.full_width} 
							style={{opacity: loading ? 0 : 1}}  
						/>
					)
				}}
			</Image> 
		)
	}

	const renderVideo = () => {
		if (data?.hero_image_or_video !== 'video' || !data?.hero_video) return;

		return (
			<Video
				url={data.hero_video}
				playsinline
				playing
				autoplay
				muted
				loop
				width={'100%'}
				height={'100%'}
				config={{
					file: {
						attributes: { 
							preload: true
						}
					}
				}}
			/>
		)
	}
	
	const renderContent = () => {
		const [ref, { opacity, x }] = useScrollAnim();

		const variants = {
			hide: {
				opacity: 0,
				x: -30,
			},
			show: {
				opacity: 1,
				x: 0,
			},
		}

		return (
			<Container>
				<PageTitle
					ref={ref}
					animate={animate ? 'show' : 'hide'}
					variants={variants}
					transition={transition}
				>
					<Mark
						size={'medium'}
					/>

					{data.logo && (
						<LogoCondensed
							src={logoCondensed}
						/>
					)}
					
					{data.title}
				</PageTitle>

				<Text	
					as={AnimatedText}
					animate={true}
					force={animate}
					text={data.hero_text}
				/>
			</Container>
		)
	}
	

	return (
		<Wrapper
			ref={props.innerRef}
			slim={slim}
		>
			{renderImage()}
			{renderVideo()}
			{renderContent()}
		</Wrapper>
	)
}

// Layout

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;

	${padding};
	position: relative;
	z-index: 2;
`

// Image

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1;
`

// Video
 
const Video = styled(ReactPlayer)`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1;
	
	video {
		object-fit: cover;
	}
`

// Page Title

const LogoCondensed = styled.img`
	margin-right: 0.1em;
	margin-left: 5px;

	${media.tablet`
		height: 36px;
		margin-left: 0px;
		margin-right: 0.05em;
	`}
`

const PageTitle = styled(motion.div)`
	display: flex;
	align-items: center;
	margin-top: auto;
	${type.heading1}

	.mark {
		width: 200px;
		margin-right: 25px;

		${media.phone`
			margin-right: 9px;
		`}
	}

	${media.tablet`
		.mark {
			width: 120px;
		}
	`}

	${media.phone`
		font-size: 50.9px;
		font-weight: 500;
		padding-top: 170px;

		.mark {
			width: 78px;
		}
	`}
`

// Text

const Text = styled.div`
	margin-top: auto;
	margin-bottom: 60px;
	margin-left: 215px;
	max-width: 765px;
	${type.heading3}

	p {
		margin-bottom: 0;
	}

	.mark {
		margin-right: 0.225em;
	}

	${media.phone`
		margin-left: 0;
		margin-bottom: 29px;
		margin-top: auto;

		.mark {
			margin-right: 0.1em;
		}
	`}
`

// Wrapper

const Wrapper = styled(FullHeight)`
	display: flex;
	height: 100vh;
	position: relative;
	max-width: 100vw;
	width: 100%;
	max-height: fit-content;

	/* Slim */

	${props => {
		if (props.slim) return css`
			max-height: 405px;

			${media.phone`
				max-height: 334px;
			`}

			${PageTitle} {
				margin-bottom: 56px;
				font-size: 80px;

				${media.phone`
					font-size: 36px;
					font-weight: 500;
					margin-bottom: 32px;

					.mark {
						width: 78px;
					}
				`}
			}

			${Text} {
				display: none;
			}
		`
	}}
`



export default PageHero
