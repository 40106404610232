import React, { useState, useEffect, useRef } from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'
import { useMount } from 'react-use';
import { forEach, find } from 'lodash'

import { Button, Icon } from '../../'

import { media } from '../../../styles/utils'
import { type, colours, bgImage } from '../../../styles/global'
import { renderError, renderLabel, inputBase } from '../shared'
import { fetchData } from '../../../services/api';

const FieldSelect = (props) => {
	const { options, selectProps, renderCustomSelect, taxonomy } = props;
	const [focused, setFocused] = useState(false)

	const getInputProps = () => {
		return {
			onChange: (e) => props.onChange(e.target?.value),
			onFocus: (e) => setFocused(true),
			onBlur: (e) => setFocused(false),
		}
	}

	const renderOptions = () => {
		if (!options) return

		return options.map((item, i) => {
			return (
				<Option 
					key={i}
					value={item.value}
				>
					{item.name}
				</Option>
			)
		})
	}

	const renderLabel = () => {
		const value = props.value && find(options, {value: parseInt(props.value)});

		return (
			<Label>
				<Heading>
					{value?.name || props.label}
				</Heading>
				<Arrow/>
			</Label>
		)
	}
	
	const renderSelect = () => {
		return (
			<HiddenSelect
				{...getInputProps()}
			>
				{renderOptions()}
			</HiddenSelect>
		)
	}
		
	return (
		<Wrapper>
			{renderSelect()}
			{renderLabel()}
			{renderError(props, focused)}
		</Wrapper>
	)
}


// Shared

const Heading = styled.h2``
const Subheading = styled.h3``

// Layout

const Wrapper = styled.div`
	display: flex;
	position: relative;

	${media.phone`
		height: 24px;
	`}
`	

// Label

const Label = styled.div`
	display: flex;
	align-items: flex-end;
	margin-left: 4px;

	${Heading} {
		${inputBase};
	}

	${media.phone`
		margin-left: 2px;

		${Heading} {
			transform: translateY(5px);
			max-width: 84vw;
			text-overflow: ellipsis;
			height: 24px;
			white-space: nowrap;
			overflow: hidden;
		}
	`}
`

// Select (Hidden)

const HiddenSelect = styled.select`
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 2;
`

const Option = styled.option`
	font-size: 12px;
`

const Arrow = styled.div`
	background-image: url(${require('../../../assets/icons/select-arrow.svg')});
	width: 18px;
	height: 12px;
	${bgImage};
	margin-left: 6px;
	transform: translateY(-5px);

	${media.phone`
		transform: translateY(-5px);
	`}
`

FieldSelect.wrapper = css``
FieldSelect.displayName = 'FieldSelect'

export default FieldSelect
