import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useMount } from 'react-use'
import { find, remove } from 'lodash'

import { media } from '../../../styles/utils'
import { type, colours, bgImage } from '../../../styles/global'
import { renderError, renderLabel } from '../shared'

const FieldCheckbox = (props) => {
	const { options, defaultOptions } = props
	const [activeOptions, setActiveOptions] = useState([])

	useMount(() => {
        if (defaultOptions?.length) {
            setActiveOptions(defaultOptions)
        }
	})
	
	const onChange = (item) => {
        const exists = find(activeOptions, {slug: item.slug})

        let newOptions = activeOptions;

        if (exists) {
            newOptions = remove(activeOptions, (option) => {
                return option && option.slug !== item.slug
            })
        } else {
			newOptions = [item, ...activeOptions]
        }
       
        setActiveOptions(newOptions)
        props.onChange(newOptions)
	}
	
	const renderOptions = (params) => {
		if (!options?.length) return;

		const items = options.map((option, i) => {
			return (
				<Item 
					key={i}
					onClick={() => onChange(option)}
				>
					<Radio
						active={find(activeOptions, {slug: option.slug})}
					/>

					<Info>
						{option.label && (
							<Heading>{option.label}</Heading>
						)}

						{option.description && (	
							<Text>{option.description}</Text>
						)}
					</Info>
				</Item>
			)
		})

		return <Items>{items}</Items>
	}

	return (
		<Wrapper>
			{renderOptions()}
			{renderError(props)}
		</Wrapper>
	)
}

// Shared

const Heading = styled.div``
const Text = styled.div``
const Info = styled.div``

// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`

// Item

const Items = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 21px;
`

const Item = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-basis: calc(50% - 10px);

	${Info} {
		display: flex;
		flex-direction: column;
		cursor: pointer;

		${Heading} {
			color: black;
			${type.heading4}
			transform: translateY(1px);
		}
	}

	&:not(:last-child) {
		margin-bottom: 5px;
	}
`

const Radio = styled.div`
	width: 12px;
	height: 12px;
	background: none;
	border: 2px solid black;
	position: relative;
	margin-right: 11px;
	cursor: pointer;

    ${props => {
		if (props.active) return css`
			border: 2px solid black;

            &::after {
				content: '';
				background-image: url(${require('../../../assets/icons/checkbox-tick.svg')});
				${bgImage}
				position: absolute;
				width: 10px;
				height: 9px;

				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
            }
        `
    }}
`

FieldCheckbox.wrapper = css``
FieldCheckbox.displayName = 'FieldCheckbox'

export default FieldCheckbox
