import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import ReactPlayer from 'react-player'
import { useUnmount, useMount } from 'react-use'

import { Swiper } from '../'

import { media, useBreakpoint, isClient } from '../../styles/utils'
import { container, padding, hoverState, bgImage, colours, type } from '../../styles/global'
import { defaultEase } from '../../utils/anim'

const Lightbox = (props) => {
	const { slides, data, close, activeSlide, colourTheme } = props;
	const [swiper, setSwiper] = useState(false)
	const [active, setActive] = useState(false)
	const isPhone = useBreakpoint('phone')

	useMount(() => {
		document.body.style.overflow = 'hidden';
		
		setTimeout(() => {
			setActive(true)
		}, 250);
	})

	useUnmount(() => {
		document.body.style.overflow = 'auto';
	})

	const toDataURL = (url) => {
		return fetch(url).then((response) => {
				return response.blob();
			}).then(blob => {
				return URL.createObjectURL(blob);
			});
	}

	const closeModal = (params) => {
		setActive(false)
			
		setTimeout(() => {
			close()
		}, 450);
	}
	
	const getDownloadLink = async () => {
		const slide = slides[swiper?.realIndex];

		if (slide) {
			const a = document.createElement("a");
			a.href = await toDataURL(slide.image?.sizes?.full_width);
			a.setAttribute('target', '_blank');
			a.setAttribute('download', slide.image?.filename);
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a)
		}
	}

	const onSliderMount = (swiper) => {
		// Swiper loss of click events on duplicate slides workaround

		const slides = document.querySelectorAll('.slides');
		const fakeFirstSlide = slides[slides.length - 1];
		const fakeLastSlide = slides[0];

		const onDuplicateClick = (e) => {
			if (e.toElement) {
				if (e.toElement.innerText == '>') swiper && swiper.slideNext()
				if (e.toElement.innerText == '<') swiper && swiper.slidePrev()
			}
		}
		
		fakeFirstSlide.addEventListener('click', (e) => onDuplicateClick(e));
		fakeLastSlide.addEventListener('click', (e) => onDuplicateClick(e));
	}

	const getVideoURL = (iframe) => {
		if (iframe) {
			return iframe?.match(/src="(.+?)"/)?.[1]
		}
	}

	const renderSlides = (slides, renderControls) => {
		return slides.map((item, i) => {			
			return (
				<Slide
					key={i}
					className={'slides'}
				>
					{item.image && (
						<BGImage
							image={item.image?.sizes?.full_width}
						/>
					)}

					{item.video && (
						<Video
							url={getVideoURL(item.video)}
							controls
							playsinline={!isPhone}
							fullscreen
							playing={activeSlide == i}
							width={'100%'}
							height={'100%'}
						/>
					)}
				</Slide>
			)
		})
	}
	
	const renderSlider = () => {
		return (
			<SwiperWrapper
				{...swiperAnim}
			>
				{active && (
					<Swiper
						slides={slides}
						renderSlides={renderSlides}
						sliderStyles={sliderStyles}
						activeSlide={activeSlide}
						onSliderMount={onSliderMount}
						setSwiper={setSwiper}
						display
						sliderParams={{
							effect: 'slide',
							speed: 750,
							keyboard: true
						}}
					/>	
				)}
			</SwiperWrapper>
		)
	}

	return (
		<Wrapper
			active={active}
			animate={active ? 'show' : 'hide'}
		>			
			<Close
				onClick={closeModal}
				{...uiAnim}
			>
				Close
			</Close>

			{!props.isVideo && (
				<Download
					onClick={getDownloadLink}
					{...uiAnim}
				>
					Download
				</Download>
			)}

			<Background
				{...backgroundAnim}
			/>

			<Next
				onClick={() => swiper && swiper.slideNext()}
				{...uiAnim}
			/>

			<Prev
				onClick={() => swiper && swiper.slidePrev()}
				{...uiAnim}
			/>
			
			{renderSlider()}
		</Wrapper>
	)
}


// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``

const Video = styled(ReactPlayer)`
	width: 100% !important;
`

// Layout

const Wrapper = styled(motion.div)`
	width: 100vw;
	height: 100vh;

	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 11;

	display: flex;
	flex-direction: column;
	align-items: center;

	pointer-events: none;

	${props => {
		if (props.active) return css`
			pointer-events: all;
		`
	}}
	
`

const Container = styled.div`
	${container}
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`

// Background

const backgroundAnim = {
	transition: {
		duration: 0.45,
		easing: 'easeOut'
	},
	variants: {
		hide: {
			scaleY: 0,
			originY: 1,
			transition: {
				delay: 0.1
			}
		},
		show: {
			scaleY: 1,
			originY: 1,
		},
	},
	initial: {
		scaleY: 0,
		originY: 1,
		transition: {
			delay: 0.1
		}
	}
}

const Background = styled(motion.div)`
	background: black;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	transform: scaleY(0);
`

// Slider

const swiperAnim = {
	transition: {
		duration: 0.35,
		easing: defaultEase
	},
	variants: {
		hide: {
			opacity: 0,
			y: 20,
		},
		show: {
			opacity: 1,
			y: 0,
			transition: {
				delay: 0.65
			}
		},
	},
	initial: {
		opacity: 0,
		y: 20,
	}
}

const SwiperWrapper = styled(motion.div)`
	z-index: 2;
`

const sliderStyles = css`
	height: 100vh;
	width: 100vw;
`

const Close = styled(motion.div)`
	${type.heading5};
	cursor: pointer;

	position: fixed;
	top: 30px;
	right: 30px;
	z-index: 3;
`

const Download = styled(motion.div)`
	${type.heading5};
	cursor: pointer;

	position: fixed;
	top: 30px;
	left: 30px;
	z-index: 3;

	${media.phone`
		display: none;
	`}
`

// Controls

const nextPrev = css`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-image: url(${require('../../assets/icons/slider-arrow.svg')});
	${bgImage}
	width: 40px;
	height: 50px;
	background-size: 13px 19px;
	z-index: 3;
	transition: transform 0.25s ease;
	cursor: pointer;
`

const Next = styled(motion.div)`
	${nextPrev}
	right: 22px;

	&:hover {
		transform: translateY(-50%) translateX(5px);
	}
`

const Prev = styled(motion.div)`
	${nextPrev}
	left: 22px;
	transform: translateY(-50%) rotate(180deg);

	&:hover {
		transform: translateY(-50%) rotate(180deg) translateX(5px);
	}
`

// Slide

const BGImage = styled(motion.div)`
	background-image: url(${props => props.image});  
	${bgImage};
	background-size: contain;
`

const Slide = styled(motion.div)`
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;

	${BGImage},
	${Video} {
		width: 100%;
		height: 100%;
		max-height: calc(100vh - 140px);
		max-width: calc(100vw - 440px);

		${media.phone`
			max-width: 100vw;
		`}
	}
`

// Anim Shared

const uiAnim = {
	transition: {
		duration: 0.45,
		easing: defaultEase
	},
	variants: {
		hide: {
			opacity: 0,
		},
		show: {
			opacity: 1,
			transition: {
				delay: 0.5
			}
		},
	},
	initial: {
		opacity: 0,
	},
}


export default Lightbox
