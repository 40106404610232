import React, { useState } from 'react'
import ProgressiveImage from 'react-progressive-image'
import styled, { css } from 'styled-components'
import useDimensions from 'react-use-dimensions';
import { useWindowSize } from 'react-use';
import { motion } from 'framer-motion'
import { padStart } from 'lodash'

import { Mark, AnimatedText, Block } from '..'

import { type, colours, bgImage, padding, } from '../../styles/global'
import { media, useBreakpoint } from '../../styles/utils'
import { useScrollAnim, transition } from '../../utils/anim'

const PageSection = (props) => {
	const { items, title, use_numbered_items, disableAnimation } = props;
	const isPhone = useBreakpoint('phone')

	const renderSidebar = () => {
		const [ref, { y, width }] = useDimensions();
		const [sidebarRef, {height: sidebarHeight, y: sidebarY}] = useDimensions();
		const { height } = useWindowSize()
		let animate = y < (height * 0.8);

		let opacity = animate ? 1 : 0
		let translateY = 0

		if (!isPhone && !props.disableSidebarTitleFade) {
			if ((-sidebarY) > (sidebarHeight - 450)) {
				opacity = 0
				translateY = -20
			}
		}

		return (
			<Sidebar
				ref={sidebarRef}
				className={'page-section-sidebar'}
			>
				{title && (
					<Subheading
						ref={ref}
						animate={disableAnimation !== true && {
							opacity,
							y: translateY,
							transition
						}}  
					>
						{title}
					</Subheading>
				)}
			</Sidebar>
		)
	}

	const renderItems = () => {
		if (!items) return;
		
		return items.map((item, i) => {
			const [ref, { opacity, transition }] = useScrollAnim();

			return (
				<Item
					className={'page-section-item'}
				>
					{use_numbered_items && (
						<ItemNo
							ref={ref}
							animate={{
								opacity,
								transition
							}}  
						>
							{padStart((i + 1), 2, '0')}.
						</ItemNo>
					)}

					<Block 
						key={i}
						layout={'text_image'}
						disableTextAnimation={props.disableTextAnimation}
						style={{
							marginBottom: 0
						}}
						{...item}
					/>
				</Item>
			)
		})
	}

	const renderContent = () => {
		const { renderContent } = props

		return (
			<Content
				className={'page-section-content'}
			>
				{renderContent ? renderContent() : renderItems()}
			</Content>
		)
	}	
	
	return (
		<Wrapper
			className={['page-section', props.className]}
			ref={props.innerRef}
		>
			{renderSidebar()}
			{renderContent()}
		</Wrapper>
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled(motion.h3)``
const Text = styled.div``

// Layout

const Wrapper = styled.div`
	display: flex;
	width: 100%;

	${media.tablet`
		flex-direction: column;
	`}
`

const Sidebar = styled.div`
	flex: 1;
	max-width: 175px;
	margin-right: 54px;

	${Subheading} {
		${type.heading4};
		position: sticky;
		top: 148px;
		
		${media.tablet`
			margin-bottom: 20px;
		`}
	}
`

const Content = styled.div`
	flex: 1;

	> * {
		margin-bottom: 240px;
	
		${media.tablet`
			margin-bottom: 120px;
		`}
	
		${media.phone`
			margin-bottom: 45px;

			&:last-child {
				margin-bottom: 80px !important;
			}
		`}
	}
`

// Item

const Item = styled.div`
	position: relative;

	${media.phone`
		display: flex;
		flex-direction: column;
	`}
`

const ItemNo = styled(motion.div)`
	position: absolute;
	top: 2px;
	left: -20px;
	transform: translateX(-100%);
	${type.heading4};

	${media.tablet`
		position: relative;
		transform: none;
		left: auto;
		margin-bottom: 14px;
	`}
`

export default PageSection
