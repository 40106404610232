import { useState, useEffect, useRef } from 'react'
import { motion, transform } from 'framer-motion'
import useDimensions from 'react-use-dimensions';
import { useWindowSize, useMount } from 'react-use';

export const defaultEase = [0.25, 0.1, 0.25, 1]

export const transition = {
    ease: defaultEase,
    duration: 0.5
}


export const useScrollAnim = (props = {}) => {
    const { reverse, delay } = props 
    const { height } = useWindowSize()
    const [origWidth, setOrigWidth] = useState(0)
    const [ref, { y: scrollY, width }] = useDimensions();
    const scrollThreshold = props.threshold || 0.8

    useMount(() => {
        width && setOrigWidth(width)
    })

    useEffect(() => {
        if (width && !origWidth) {
            setOrigWidth(width)
        }
    }, [width])

    let animStyle = {}

    if (scrollY && height) {
        let animate = scrollY < ((height * scrollThreshold) + (delay || 0));

        if (reverse) {
            animate = scrollY > ((height * scrollThreshold) + (delay || 0));
        }

        const opacity = animate ? 1 : 0
        const x = animate ? 0 : -30
        const y = animate ? 0 : 30
        const scaleX = animate ? 1 : 0
        const width = animate ? origWidth : 0

        animStyle = { 
            opacity, 
            x, 
            y,
            scaleX,
            width
        }
    }

    return [
        ref, 
        animStyle
    ]
}