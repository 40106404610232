import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

import { media } from '../../styles/utils'

export const resolveMark = (string) => {
	string = string.replace(' ', '');

	const resolveSize = (charLength) => {
		const lengths = {
			1: 'small',
			2: 'medium',
			3: 'large'
		}
	
		return lengths[charLength];
	}
	
	return {
		mark: true,
		size: resolveSize(string.length),
	}
}

export const parseMark = (html) => {
    let parser = new DOMParser();
    let document = parser.parseFromString(html, 'text/html');
	let matches = document.getElementsByTagName('mark')

    if (matches.length) {
		let mark = matches[0];

		return resolveMark(mark.innerText)
	}
	
	return false
}

const Mark = (props) => {
	const { size } = props;

	return (
		<Wrapper
			className={'mark'}
			{...props}
		/>
	)
}

const Wrapper = styled(motion.div)`
	background: white;
	margin-right: 0.1em;
	height: 0.71em;

	${props => {
		if (props.size == 'small') return css`
			width: 0.83em;
		`

		if (props.size == 'medium') return css`
			width: 1.66em;

			${media.phone`
				width: 1.2em;
			`}
		`

		if (props.size == 'large') return css`
			width: 2.5em;
		`
	}}
`

export default Mark
