import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useMount } from 'react-use'

import { Mark } from '../../'

import { media } from '../../../styles/utils'
import { type, colours } from '../../../styles/global'
import { renderError, renderLabel, inputBase, hasError } from '../shared'
import { defaultEase } from '../../../utils/anim'

const FieldInput = (props) => {
	const [focused, setFocused] = useState(false)

	const getInputProps = () => {
		return {
			onChange: (e) => props.onChange(e.target?.value),
			onFocus: (e) => setFocused(true),
			onBlur: (e) => setFocused(false),
		}
	}

	return (
		<Wrapper
			error={hasError(props, focused)}
		>
			<Mark
				animate={focused ? 'show' : 'hide'}
				{...markAnim}
				size={'medium'}
			/>

			<Input 
				{...props}
				{...getInputProps()}
			/>

			{renderError(props, focused)}
		</Wrapper>
	)
}

// Input

export const inputStyle = css`
   	${inputBase}
   	transition: all 0.25s ease;
	line-height: initial;

    &, &::placeholder {
        ${type.heading3};
		line-height: initial;
    }

    &:focus,
	&:hover {
		outline: none;
    }
`

const Input = styled.input`
    ${inputStyle};
	height: auto;
`

// Mark Animation

const markAnim = {
	transition: {
		duration: 0.45,
		easing: defaultEase
	},
    variants: {
		hide: {
			width: 0
		},
		show: {
			width: 50
		}
	}
}

// Layout

const Wrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	height: 32px;

	.mark {
		${type.heading3};
	}

	${props => {
		if (props.error) return css`			
			input {
				&, &::placeholder {
					color: ${colours.red}
				}
			}
		`
	}}

	${media.phone`
		margin-bottom: -8px;
	`}
`

FieldInput.wrapper = css``
FieldInput.displayName = 'FieldInput'

export default FieldInput
