import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { navigate, Link } from 'gatsby'
import { useLocation, useUnmount } from 'react-use';
import { motion, AnimatePresence } from 'framer-motion';
import { useWindowScroll, useWindowSize, useMount } from 'react-use'

import { media } from '../../styles/utils'
import { container, type, colours, padding, hoverState, bgImage } from '../../styles/global'
import { defaultEase } from '../../utils/anim'

const Header = (props) => {
	const [menuActive, setMenuActive] = useState(false)
	const [mounted, setMounted] = useState(false)
	const location = useLocation();
	const { y: scrollY } = useWindowScroll()
	const { height } = useWindowSize()
	const y = mounted ? scrollY : 0
	const [BGVisible, setBGVisible] = useState(false)

	useMount(() => {     
        setTimeout(() => {
            setMounted(true)
        }, 100);
	})
	
	useEffect(() => {
		if (y > height && !BGVisible) setBGVisible(true)
		if (y < height && BGVisible) setBGVisible(false)
	}, [y])

	const toggleMenu = () => {
		setMenuActive(!menuActive)
		document.body.style.overflow = !menuActive == true ? 'hidden' : 'auto';
	}

	const renderMenuItems = () => {
		const menuItems = [
			{
				title: 'Home',
				link: '/',
			},
			{
				title: 'About',
				link: '/about',
			},
			{
				title: 'Speaking',
				link: '/speaking',
			},
			{
				title: 'CR/Live',
				link: '/live',
			},
			{
				title: 'CR/Insights',
				link: '/insights',
			},
			{
				title: 'Connect',
				link: '/connect',
			},
		]

		const items = menuItems.map((item, i) => {
			return (
				<MenuItemWrapper
					key={item.link}
					{...menuItemAnim(i, menuActive)}
				>
					<MenuItem
						as={Link}
						to={item.link}
						onClick={toggleMenu}
					>
						<Mark/>
						<Heading>
							{item.title}
						</Heading>
					</MenuItem>
				</MenuItemWrapper>
			)
		})

		return (
			<MenuItems>
				<AnimatePresence>
					{menuActive && items}
				</AnimatePresence>
			</MenuItems>
		)
	}

	const renderMenu = () => {
		return (
			<Menu
				active={menuActive}
				animate={menuActive ? 'show' : 'hide'}
			>
				<MenuBackground
					{...menuBGAnim}
				/>

				{renderMenuItems()}
			</Menu>
		)
	}

	return (
		<Wrapper>
			{!props.hideMenu && (
				<MenuToggle
					onClick={toggleMenu}
				>
					{menuActive ? 'Close' : 'Menu'}
				</MenuToggle>
			)}


			{!props.hideLogo && (
				<Logo
					as={Link}
					to={'/'}
				/>
			)}

			{!props.hideLogo && (
				<BackgroundFade
					style={{
						opacity: BGVisible ? 1 : 0
					}}
				/>
			)}

			{renderMenu()}
		</Wrapper>
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

// Layout

const Container = styled.div`
	${container}
	${padding}
	position: relative;
	display: flex;
	max-width: none;
	z-index: 11;
	position: relative;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	z-index: 10;
`

// Menu Toggle

const MenuToggle = styled.div`
	${type.heading5};
	cursor: pointer;

	position: fixed;
	top: 30px;
	right: 30px;
	z-index: 11;

	${media.phone`
		top: 15px;
		right: 15px;
	`}
`

// Logo

const Logo = styled.div`
	background-image: url(${require('../../assets/images/cr-logo-header.svg')});
	${bgImage};
	width: 200px;
	height: 57px;

	position: fixed;
	top: 30px;
	left: 30px;
	z-index: 5;
	
	${media.phone`
		width: 140px;
		height: 40px;
		top: 15px;
		left: 15px;
	`}
`

/* Menu */

const Menu = styled(motion.div)`
	position: fixed;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 10;

	display: flex;
	flex-direction: column;
	padding: 30px;

	/* ${media.phone`
		padding: 40px 46px;
	`} */

	${media.phone`
		padding: 15px 15px;
	`}

	/* Animation */

	pointer-events: none;

	${props => {
		if (props.active) return css`
			pointer-events: all;
		`
	}}
`

const menuBGAnim = {
	transition: {
		duration: 0.45,
		easing: 'easeOut'
	},
	variants: {
		hide: {
			scaleY: 0,
			originY: 1,
			transition: {
				delay: 0.1
			}
		},
		show: {
			scaleY: 1,
			originY: 1,
		},
	}
}

const MenuBackground = styled(motion.div)`
	background: black;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
`

const MenuItems = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`

// Menu Item

const Mark = styled.div``

const menuItemAnim = (i, menuActive) => {
	const delay = 0.1;

	return {
		transition: {
			duration: 0.35,
			easing: 'easeOut'
		},
		initial: {
			opacity: 0,
			x: -20,
		},
		exit: {
			opacity: 0,
			x: 0,
		},
		animate: {
			opacity: 1,
			x: 0,
			transition: {
				delay: 0.55 + (delay * i)
			}
		},
	}
}

const MenuItemWrapper = styled(motion.div)`
	position: relative;
	z-index: 2;
	overflow: hidden;
`

const MenuItem = styled.div`
	display: flex;
	align-items: center;

	${Heading},
	${Mark} {
		${type.heading2}
	}

	${Mark} {
		background: ${colours.lemon};
		margin-right: 0.1em;
		height: 0.71em;
		width: 0;
		transition: all 0.25s ease;
	}

	&:hover {
		${Mark} {
			width: 100px;
		}

		${media.phone`
			${Mark} {
				width: 0;
			}
		`}
	}
`

// Background Fade

const BackgroundFade = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	height: 130px;
	background-image: linear-gradient(180deg, #000000 0%, rgba(0,0,0,0.68) 56%, rgba(0,0,0,0.00) 100%);
	z-index: 2;
	pointer-events: none;

	/* Animation */

	opacity: 0;
	transition: opacity 0.45s ease;

    ${media.phone`
        height: 100px;
    `}
`

export default Header
