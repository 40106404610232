import React, { useState, useEffect } from 'react'
import { navigate, Link, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { default as SwiperComp } from 'react-id-swiper'
import { useMount } from 'react-use';
import { motion } from 'framer-motion'

import { media } from '../../styles/utils'
import { container, bgImage, padding, hoverState } from '../../styles/global'

const Swiper = (props) => {
	const {slides, sliderStyles, controls, controlLabels} = props;
	const [caption, updateCaption] = useState(null);
	const [activeIndex, updateActiveIndex] = useState(0);
	const [swiper, updateSwiper] = useState(null);
	const canLoop = slides && slides.length > 1;
	
	const swiperParams = {
		getSwiper: updateSwiper,
		loop: canLoop,
		initialSlide: props.activeSlide > -1 ? props.activeSlide : 0,
		...props.sliderParams
	}

	useMount(() => {
		onSlideChange()
	})

	useEffect(() => {
		if (swiper) {
			swiper.on('slideChange', onSlideChange);
			props.setSwiper && props.setSwiper(swiper)
		}
	}, [swiper])

	useEffect(() => {
		if (props.activeSlide == null || !swiper) return;
		swiper.slideTo(props.activeSlide, 1000)
	}, [props.activeSlide])

	const onSlideChange = () => {
		updateActiveIndex(swiper ? (swiper.realIndex) : 0)
	}

	const renderSlide = (item, i) => {
		const image = item.image;

		return (
			<Slide>
				<BGImage 
					image={image} 
				/>
			</Slide>
		)
	}

	const renderSlides = () => {
		if (!slides || !slides.length) return;

		return slides.map((item, i) => {
			return renderSlide(item, i)
		})
	}

	return (
		<Wrapper
			sliderStyles={sliderStyles}
			{...props.wrapperProps}
		>
			<SwiperComp
				{...swiperParams}
			>
				{props.renderSlides && props.renderSlides(slides, swiper, activeIndex) || renderSlides()}
			</SwiperComp>
		</Wrapper>
	)
}


// Shared

const Description = styled.div``
const Subheading = styled.div``
const Icon = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const defaultSliderStyles = css`
	width: 49vw;
	max-width: 708px;

	${media.tablet`
		width: calc(100vw - 34px);
	`}
`

const Wrapper = styled(motion.div)`
	position: relative;

	.swiper-container {
		${props => props.sliderStyles || defaultSliderStyles}
	}
`

// Slide

const Slide = styled.div`
	display: flex;

	${BGImage} {
		height: 100%;
		padding-bottom: 80%;
		flex: 1;
	}
`


export default Swiper
