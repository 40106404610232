export const industryOptions = [
    {
        value: false,
        name: "Select an industry"   
    },
    {
        value: 1,
        name: "Aerospace and Defence"   
    },
    {
        value: 2,
        name: "Asset and Wealth Management"
    },
    {
        value: 4,
        name: "Automotive"
    },
    {
        value: 8,
        name: "Banking and Capital Markets"
    },
    {
        value: 16,
        name: "Business Services"
    },
    {
        value: 32,
        name: "Capital Projects and Infrastructure"
    },
    {
        value: 64,
        name: "Charities & Not For Profit"
    },
    {
        value: 128,
        name: "Chemicals"
    },
    {
        value: 256,
        name: "Education"
    },
    {
        value: 512,
        name: "Engineering and Construction"
    },
    {
        value: 1024,
        name: "Financial Services"
    },
    {
        value: 2048,
        name: "Forest, Paper and Packaging"
    },
    {
        value: 4096,
        name: "Government and Public Services"
    },
    {
        value: 8192,
        name: "Healthcare"
    },
    {
        value: 16384,
        name: "Hospitality and Leisure"
    },
    {
        value: 32768,
        name: "Insurance"
    },
    {
        value: 65536,
        name: "Manufacturing"
    },
    {
        value: 131072,
        name: "Media and Entertainment"
    },
    {
        value: 262144,
        name: "Mining and Metals"
    },
    {
        value: 524288,
        name: "Oil and Gas"
    },
    {
        value: 1048576,
        name: "Pharmaceutical and Life Sciences"
    },
    {
        value: 2097152,
        name: "Power and Utilities"
    },
    {
        value: 4194304,
        name: "Private Equity"
    },
    {
        value: 8388608,
        name: "Real Estate"
    },
    {
        value: 16777216,
        name: "Retail and Consumer"
    },
    {
        value: 33554432,
        name: "Technology"
    },
    {
        value: 67108864,
        name: "Telecommunications"
    },
    {
        value: 134217728,
        name: "Transport and Logistics"
    }
]



