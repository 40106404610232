import React, { useState, useContext, } from 'react'
import { navigate, Link } from 'gatsby'
import { useInterval, useMount } from 'react-use'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { motion, AnimatePresence, transform } from 'framer-motion'
import useDimensions from 'react-use-dimensions';
import { useWindowSize } from 'react-use';

import { MarkLink } from '../'

import { media } from '../../styles/utils'
import { container, padding, colours, type, bgImage, bgIcon, hoverState } from '../../styles/global'
import { getGlobalData } from '../../utils'
import { subscribeContext } from '../Subscribe/SubscribeProvider'

const Footer = (props) => {
    const { data } = getGlobalData('')
    const [onTourTime, setOnTourTime] = useState(null)
    const [showCredits, setShowCredits] = useState(0)
    const { height } = useWindowSize()
    const [ref, { y, height: footerHeight }] = useDimensions();
    const subscribe = useContext(subscribeContext)

    useMount(() => {
        setOnTourTime(formatTimeZone())
    })

    const formatTimeZone = () => {
        if (data?.footer?.on_tour_display) {
            return moment.tz(moment(),data?.footer?.on_tour_timezone).format('h:mm a')
        }
    }

    useInterval(
        () => {
            setOnTourTime(formatTimeZone())
        },
        data?.footer?.on_tour_display ? 60000 : null 
    )

    const getCoverAnimProps = () => {
        let start = height;
        let end = height - footerHeight + 50;
        const scaleY = transform(y, [start, end], [1, 0])

        return {
            style: {
                scaleY,
                originY: 0
            }
        }
    }

    const renderInfo = () => {
		return (
			<Info>
                {!props.contact && (
                    <Contact>
                        <Section
                            as={'a'}
                            target={'_blank'}
                            href={`mailto:${data.footer.footer_email}`}
                        >
                            <Subheading
                                dangerouslySetInnerHTML={{__html: data.footer.footer_email}}
                            />
                            <MarkLink
                                disableAnimation    
                            >
                                Get in touch
                            </MarkLink>
                        </Section>

                        <Section>
                            <Subheading>CR/INSIGHTS</Subheading>
                            <MarkLink
                                disableAnimation
                                onClick={() => {
                                    subscribe.setDefaultList('insights')
                                    subscribe.setActive(true)
                                }}
                            >
                                Get updates
                            </MarkLink>
                        </Section>
                    </Contact>
                )}

                {props.contact}
				
				{data.footer.on_tour_display && (
                    <OnTour
                        dangerouslySetInnerHTML={{__html: `
                            ${data.footer.on_tour_title || 'On Tour'}:<br/>
                            ${data.footer.on_tour_location}<br/>
                            ${onTourTime}
                        `}}
                    />
                )}

                <Logo/>
			</Info>
		)
    }

    const renderSocialLinks = () => {
        const items = data.footer.social_links.map((item, i) => {
            return (
                <Subheading
                    key={i}
                    as={'a'}
                    target={'_blank'}
                    href={item.link}
                >
                    {item.title}
                    <Divider>
                        {i < (data.footer.social_links.length - 1) && ' /'}      
                    </Divider>
                </Subheading>
            )
        })

        
        return (
            <SocialLinks>
                {items}
            </SocialLinks>
        )
    }
    
    const renderBottom = () => {
        return (
            <Bottom>
                {renderSocialLinks()}

                <SiteCredits>
                    <AnimatePresence>   
                        {!showCredits && (
                            <CreditsHeading
                                key="credits-heading"
                                onMouseEnter={() => setShowCredits(true) }
                                {...creditsHeadingAnim}
                            >
                                Brand Partners
                            </CreditsHeading>
                        )}
                        
                        {showCredits && (
                            <CreditsText
                                key="credits-text"
                                dangerouslySetInnerHTML={{__html:data.footer.site_credits}}
                                onMouseLeave={() => setShowCredits(false) }
                                {...creditsTextAnim}
                            />
                        )}
                    </AnimatePresence>
                </SiteCredits>
            </Bottom>
        )
    }

	return (
        <FixedWrapper>
            <Wrapper
                ref={ref}
            >
                <Container>
                    {renderInfo()}
                    {renderBottom()}
                </Container>	

                {/* <Cover
                    {...getCoverAnimProps()}
                /> */}
            </Wrapper>
        </FixedWrapper>
	)
}

// Shared

const Subheading = styled.h3``
const Description = styled.div``
const Section = styled.div``

// Layout

const FixedWrapper = styled.div`
    padding-top: 530px;

    ${media.phone`
        padding-top: 381px;
    `}
`

const Wrapper = styled.div`
    z-index: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
	display: flex;
	justify-content: center;
    background-color:  white;
    overflow: hidden;

    a, p, div {
        color: black;
        text-transform: uppercase;
    }
`

const Container = styled.div`
	${container}
	${padding}
	
	display: flex;
    flex-direction: column;
    height: 530px;
	padding-top: 108px;

    ${media.phone`
        padding-top: 53px;
        height: 381px;
    `}
`

// Cover

const Cover = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    z-index: 2;
    will-change: transform;
`

// Info 

const Info = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;    
`

const Contact = styled.div`
    ${Section} {
        ${Subheading} {
            ${type.heading4};
            margin-bottom: 20px;

            ${media.phone`
                margin-bottom: 7px;
            `}
        }

        &:last-child {
            margin-top: 44px;
        }
    }
`

const Logo = styled.div`
    ${bgIcon};
    background-image: url(${require('../../assets/images/CR-logo.svg')});
    width: 90px;
    height: 72px;

    ${media.phone`
        position: absolute;
        bottom: 15px;
        right: 15px;
        width: 64px;
        height: 50px;
    `}
`

// On Tour

const OnTour = styled.div`
    ${type.heading5}
    line-height: 1em;

    ${media.phone`
        display: none;
    `}
`

// Social Links

const Divider = styled.div`
    ${media.phone`
        display: none;
    `}
`

const SocialLinks = styled.div`
	display: flex;
	
	${Subheading} {	
        display: flex;

        ${Divider} {
            margin-left: 6px;
        }

		&:not(:last-child) {
			margin-right: 6px;

            ${media.phone`
                margin-bottom: -4px;
            `}
		}
	}

    ${media.phone`
        flex-direction: column;
    `}
`

// Bottom

const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;    

    margin-top: auto;
    margin-bottom: 38px;

    ${media.phone`
        flex-direction: column;
        margin-bottom: 15px;
    `}
`

// Site Credits

const SiteCredits = styled.div`
    position: relative;
    flex: 1;

    a {
        box-shadow:
            inset 0 -0.175em white,
            inset 0 -0.22em black;
    }

    ${media.phone`
        margin-top: 52px;
        width: 100%;

        p {
            margin-bottom: 0;
        }
    `}
`

const creditsHeadingAnim = {
    initial: { 
        opacity: 0,
    },
    animate: { 
        opacity: 1,
        transition: { 
            duration: 0.5 
        },
    },
    exit: { 
        opacity: 0,
        transition: { 
            duration: 0.2 
        },
    }
}

const CreditsHeading = styled(motion.div)`
    position: absolute;
    right: 0;
    top: 0;

    ${media.phone`
        right: auto;
        top: auto;
        left: 0;
        bottom: 0;
    `}
`

const creditsTextAnim = {
    initial: { 
        opacity: 0,
        x: 500
    },
    exit: { 
        opacity: 0,
        x: 500,
        transition: { 
            duration: .2 
        },
    },
    animate: {
        x: 0,
        opacity: 1
    },
    transition: { 
        duration: 0.5
    }
}

const CreditsText = styled(motion.div)`
    position: absolute;
    right: 0;
    top: 0;

    ${media.phone`
        right: auto;
        top: auto;
        left: 0;
        bottom: 0;
        max-width: 200px;
    `}
`



export default Footer
