import { 
    FieldInput,
    FieldSelect,
    FieldCheckbox,
    FieldTextArea,
    FieldDatePicker,
    FieldProfileImage,
    FieldPasswordGenerator,
    FieldRadioButtons,
    FieldFloorPlan,
    FieldSetFloorPlan,
} from './templates'

export const resolveField = (type) => {
    let field;

    // Map layout strings to fields

    const fields = {
        'text': FieldInput, // String
        'password': FieldInput,
        'email': FieldInput,
        'number': FieldInput,
        'tel': FieldInput, 
        'select': FieldSelect, // Object {inputValue, option: {label, slug}}
        'checkbox': FieldCheckbox, // Object {label, slug}
        'textarea': FieldTextArea, // String
    }
    
    return fields[type]
}

