import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { Swiper } from '../'
import Fade from 'react-reveal/Fade'

import { media, useBreakpoint, isClient } from '../../styles/utils'
import { container, padding, hoverState, bgIcon, colours, type, maxWidth } from '../../styles/global'
import { defaultEase } from '../../utils/anim'

const FeaturedEngagements = (props) => {
	const { slides, activeSlide, logosOnly } = props;
    const [swiper, setSwiper] = useState(false)
    const isPhone = useBreakpoint('phone')

	const renderSlides = (slides) => {
		return slides.map((item, i) => {
			return (
				<Slide
					key={i}
                    className={'slides'}
                    logosOnly={logosOnly}
				>
                    <Fade
                        left
                        distance={'30px'}
                        delay={i * 100}
                        force={isPhone || i > 3}
                        fraction={0.01}
                    >
                        <BGIcon
                            image={item?.logo?.url}
                        />
                    
                        {item.title && (
                            <Text>{item.title}</Text>
                        )}
                    
                        {item.short_text && (
                            <Info dangerouslySetInnerHTML={{
                                __html: item.short_text,
                            }}/>
                        )}
                    </Fade>
				</Slide>
			)
		})
	}
	
	return (
        <Wrapper>
            <Container>
                <Heading>{props.heading || 'Featured Engagements'}</Heading>
            </Container>

            <SwiperWrapper>
                <Swiper
                    slides={slides}
                    renderSlides={renderSlides}
                    activeSlide={activeSlide}
                    setSwiper={setSwiper}
                    sliderStyles={sliderStyles}
                    display
                    sliderParams={{
                        slidesPerView: 'auto',
                        spaceBetween: isPhone ? 20 : 60,
                        loop: false,
                        freeMode: true,
                        scrollbar: {
                            el: '.scrollbar',
                            hide: false,
                            draggable: true,
                        }
                    }}
                />
            </SwiperWrapper>
        
		</Wrapper>
	)
}

// Shared

const BGIcon = styled.div`
    background-image: url(${props => props.image});  
    ${bgIcon};
`

// Layout

const Wrapper = styled.div`
    width: 100vw;
    margin-bottom: 240px;
    max-width: calc(100vw - 20px);
    display: flex;
    align-items: center;
    flex-direction: column;

    ${media.tablet`
        margin-bottom: 120px;
    `}

    ${media.phone`
        margin-bottom: 70px;
        max-width: calc(100vw - 5px);
    `}

    .scrollbar {
        width: 100%;
        max-width: ${maxWidth}px;
		background: #505050;
        height: 4px;
        margin-top: 58px;
        
		.swiper-scrollbar-drag {
			border-radius: 0;
			background-color: white;
			margin-right: 0;
		}
	}
`

const Container = styled.div`
	${container}
    display: flex;
    flex-direction: column;
`

const Heading = styled.h2`
    margin-bottom: 56px;
    ${type.heading4}
    ${padding}
`

// Slider

const SwiperWrapper = styled.div`
    position: relative;
    width: 100%;
`

const sliderStyles = css`
    ${padding}
    padding-right: 0;
    max-width: calc(100vw - 20px);
    padding-left: calc(((100vw - 1680px) / 2) + 20px);

    ${media.desktop`
        ${padding}
    `}

    ${media.phone`
        max-width: 100vw;
    `}
`

// Slide

const Image = styled.div``
const Text = styled.div``
const Info = styled.div``

const Slide = styled.div`
    
    &,
    .react-reveal {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 370px !important;
    }

	${BGIcon} {
		width: 100%;
		height: 42px;
        background-position-x: left;

        ${media.phone`
            height: 56px;
            max-width: 200px;
        `}
	}

    ${Text} {
        ${type.heading5};
        margin-bottom: 15px;
        margin-top: 58px;

        ${media.phone`
            margin-top: 48px;
        `}
    }

    ${Info} {
        ${type.body};

        p:last-child {
            margin-bottom: 0;
        }
    }

    ${media.phone`
        &,
        .react-reveal {
            width: 260px !important;
        }
    `}

    /* Logo only */

    ${props => {
        if (props.logosOnly) return css`
            ${BGIcon} {
                height: 64px;
                max-width: 250px;
            }
        `
    }}
`

export default FeaturedEngagements
